import { bindable, bindingMode } from 'aurelia-framework';

export class Expander {
    static inject = [Element];
    _element;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) expanded;
    @bindable downIcon = 'fa-solid fa-chevron-down';
    @bindable upIcon = 'fa-solid fa-chevron-up';
    @bindable downKey;
    @bindable upKey;
    @bindable isButton = false;

    constructor(element) {
        this._element = element;
    }

    toggleExpand() {
        this.expanded = !this.expanded;
        this._element.dispatchEvent(new CustomEvent('toggled', { bubbles: true, detail: { expanded: this.expanded } }));
    }
}
