import { bindable } from 'aurelia-framework';
import { Favorites } from 'services/favorites';

export class Favorite {
    static inject = [Favorites];
    _favorites;

    @bindable({ changeHandler: '_load' }) type;
    @bindable({ changeHandler: '_load' }) id;
    isFavorite = false;

    constructor(favorites) {
        this._favorites = favorites;
    }

    async _load() {
        if (!this.type || !this.id) return;
        try {
            const ids = await this._favorites.ids(this.type);
            this.isFavorite = ids.indexOf(this.id) >= 0;
        } catch (err) {
            console.log(err);
        }
    }

    async setFavorite(isFavorite) {
        try {
            await this._favorites.set(this.type, this.id, isFavorite);
            this.isFavorite = isFavorite;
        } catch (err) {
            console.log(err);
        }
    }
}
