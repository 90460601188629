import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Carriers } from 'services/carriers';
import { c } from 'common/common';

export class FavoriteCarriers {
    static inject = [EventAggregator, Security, Carriers];

    @bindable showTitle = false;
    _ea;
    _security;
    _carriers;

    constructor(ea, security, carriers) {
        this._ea = ea;
        this._security = security;
        this._carriers = carriers;
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.carriers = await this._carriers.favorites();
        } catch (err) {
            console.log(err);
        }
    }

    isJotform(src) {
        return src.indexOf('https://form.jotform.com') === 0;
    }

    openLightbox(src) {
        const link = c.Helpers.replaceVariables(this._security, src, true);
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: link });
    }
}
